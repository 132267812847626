export const environment = {
  production: true,
  name: 'prod',
  endpoints: (() => {
    const baseUrl = 'https://bolkart.az/api/bolkart-service/public';
    return {
      faqs: {
        all: `${baseUrl}/faqs`,
        byId: `${baseUrl}/faqs/{id}`,
        categories: `${baseUrl}/faq-categories`,
      },
      campaings: {
        all: `${baseUrl}/campaigns`,
        home: `${baseUrl}/home/campaigns`,
        byId: `${baseUrl}/campaigns/{id}`,
        types: `${baseUrl}/campaign-types`,
      },
      news: {
        all: `${baseUrl}/news`,
        byId: `${baseUrl}/news/{id}`,
      },
      home: {
        cards: `${baseUrl}/home/cards`,
        campaings: `${baseUrl}/home/campaigns`,
      },
      cards: {
        all: `${baseUrl}/cards`,
        byId: `${baseUrl}/cards/{id}`,
        otherById: `${baseUrl}/cards/other/{id}`,
        order: `${baseUrl}/card-order`,
        cardOrderInfo: `${baseUrl}/card-order-info/{id}`,
        imageById: `${baseUrl}/card-img/{id}`,
      },
      partners: {
        all: `${baseUrl}/partners`,
        categories: `${baseUrl}/partner-categories`,
        taksits: `${baseUrl}/taksits`,
        socialMedias: `${baseUrl}/social-medias`,
        cities: `${baseUrl}/cities`,
        cashbacks: `${baseUrl}/cashbacks`,
      },
      appeals: {
        offers: {
          all: `${baseUrl}/offers`,
          byId: `${baseUrl}/offers/{id}`,
          type: `${baseUrl}/offer-types`,
        },
        complaints: {
          all: `${baseUrl}/complaints`,
          byId: `${baseUrl}/complaints/{id}`,
        },
        bePartner: {
          all: `${baseUrl}/be-partner`,
        },
      },
      callOrder: {
        all: `${baseUrl}/call-order`,
        types: `${baseUrl}/call-order-types`,
      },
      tariffs: {
        all: `${baseUrl}/tariffs`,
      },
      cashback: {
        all: `${baseUrl}/cashback-informs`,
      },
      about: {
        all: `${baseUrl}/about`,
      }
    }
  })()};
