import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const language = this.getUserPreferredLanguage();

    if (!language) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Accept-Language': 'AZ'
      }
    });

    return next.handle(request).pipe(
      catchError((error: any) => {
        console.error("Error in LanguageInterceptor:", error);
        return throwError(error);
      })
    );

  }

  private getUserPreferredLanguage(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('userLanguage') || 'AZ'; // default to Azerbaijan
    }
    return 'AZ';
  }
}
