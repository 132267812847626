import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ScrollService } from '@app/services/scroll/scroll.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollMiddleware {
  constructor(private router: Router, private scrollService: ScrollService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      // @ts-ignore
      .subscribe((event: NavigationEnd) => {
        const isRootUrl = (event.urlAfterRedirects || event.url).split('?')[0] === '/';

        if (!isRootUrl) {
          this.scrollService.scrollToTop();
        }
      });
  }
}
