import { Component } from '@angular/core';
import { ScrollMiddleware } from './services/scroll/scroll-middleware.service';


@Component({
  selector: 'blk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private scrollMiddleware: ScrollMiddleware) {}
}
