import { Injectable } from '@angular/core';
import {fromEvent, Observable, of} from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scrollPosition$: Observable<number>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollPosition$ = fromEvent(window, 'scroll').pipe(
        map(() => window.scrollY),
        distinctUntilChanged()
      );
    } else {
      this.scrollPosition$ = of(0);
    }
  }

  setBackgroundColor(element: HTMLElement, color: string, threshold: number) {
    this.scrollPosition$.subscribe(scrollY => {
      element.style.backgroundColor = scrollY > threshold ? color : '';
    });
  }

  setBorderColor(element: HTMLElement, color: string, threshold: number) {
    this.scrollPosition$.subscribe(scrollY => {
      element.style.border = scrollY > threshold ? `1px solid ${color}` : '';
    });
  }

  scrollTo(position: number = 0, duration: number = 0) {
    if (isPlatformBrowser(this.platformId)) {
      const startPosition =
        window.scrollY || window.document.documentElement.scrollTop;
      const delta = position - startPosition;
      let startTime: number;

      const animateScroll = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const fraction = Math.min(progress / duration, 1);
        const easing =
          fraction < 0.5
            ? 2 * fraction * fraction
            : -1 + (4 - 2 * fraction) * fraction; // Quadratic easing

        window.scrollTo(0, startPosition + easing * delta);

        if (progress < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }

  scrollToTop(duration: number = 0) {
    this.scrollTo(0, duration);
  }

}
